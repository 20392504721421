import React from 'react';

import './ProductsListContainer.css';
import {
  Box,
  Typography,
  Container,
  ButtonGroup,
  Button,
  Grid,
} from '@mui/material';
import { ProductCard } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../store/actions/productActions';
import { useEffect } from 'react';
import { filterByCategory } from '../../../store/actions/ui/productListContainer/filterActions';

export function ProductsListContainer(props) {
  const dispatch = useDispatch();
  const fetchProducts = (params = { category: 'All' }) => {
    dispatch(getProducts(params));
    console.log(params);
    dispatch(filterByCategory(params));
  };

  const state = useSelector(
    (state) => state.productsList.data.productsListReducer
  );
  const uiState = useSelector(
    (state) => state.productsList.ui.productListContainerReducer
  );
  const { data: products, isFetching } = state;
  // console.log(uiState);
  const { categories, activeFilter } = uiState;
  useEffect(() => {
    fetchProducts({ category: 'All' });
  }, []);
  return (
    <Container style={{ overflow: 'hidden' }}>
      <Box mb={2} py={4}>
        <Box>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
            component="div"
          >
            Latest Products
          </Typography>
          <hr />
        </Box>
        <Box sx={{}}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
            my={2}
          >
            {categories.map((category) => (
              <Button
                onClick={() => {
                  fetchProducts({ category });
                }}
                variant={category === activeFilter ? 'contained' : 'outlined'}
                color="primary"
                key={category}
              >
                {category}
              </Button>
            ))}
          </Box>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {!isFetching && products && products.length ? (
              products.map((product, i) => {
                return (
                  <Grid item xs={12} sm={4} md={3} key={i}>
                    <ProductCard product={product}></ProductCard>
                  </Grid>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
