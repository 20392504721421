import React, { useState, useEffect } from 'react';
import './Product.css';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import { getProduct } from '../../store/actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import { addCart } from '../../store/actions/cartActions';
import { openDialog } from '../../store/actions/ui/navbarActions';

export function Product() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // const [product, setProduct] = useState();
  const productState = useSelector(
    (state) => state.productPage?.data?.productReducer
  );
  const { data: product } = productState;
  const { isFetching } = productState;
  const navbarState = useSelector((state) => state.navbar);
  const { isDialogOpen } = navbarState;
  const addProduct = () => {
    dispatch(addCart(product));
  };
  // getProduct(id)(dispatch);
  useEffect(() => {
    if (!(product && product.id) || product.id != id) dispatch(getProduct(id));
  }, [id]);
  console.log(product, isFetching);
  return (
    <>
      {isFetching || !product ? (
        <Container style={{ backgroundColor: 'white' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          ></Box>
        </Container>
      ) : (
        <Container style={{ backgroundColor: 'white' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ flexGrow: 1, minWidth: '50%' }} py={2}>
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  height: 0,
                  paddingTop: 'min(95%,86vh)',
                }}
              >
                <img
                  src={product.image}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    objectFit: 'contain',
                  }}
                  alt=""
                />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                alignSelf: 'flex-start',
              }}
              py={2}
              pl={4}
            >
              <Typography
                variant="h3"
                sx={{ fontWeight: '600', fontSize: { xs: '1rem', sm: '3rem' } }}
                component="div"
                color="lightgray"
                mb={1}
              >
                {product.title}
              </Typography>
              <Typography
                color="GrayText"
                variant="h2"
                sx={{
                  fontWeight: '300',
                  fontSize: { xs: '1.1rem', sm: '3.75rem' },
                }}
                component="div"
                mb={1}
              >
                {product.category}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
                mb={2}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '1rem', sm: '1.5rem' },
                  }}
                  component="div"
                  color="black.main"
                >
                  {product.price} AED
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => addProduct()}
                  >
                    add to cart
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(openDialog())}
                  >
                    view cart
                  </Button>
                </Box>
              </Box>
              <Typography
                variant="h6"
                color="black.main"
                sx={{
                  fontSize: { xs: '0.9rem', sm: '1.25rem' },
                }}
                component="div"
              >
                {product.description}
              </Typography>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
}
