import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import './Hero.css';
export function Hero() {
  return (
    <>
      <Box
        component="div"
        sx={{
          height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' },
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          },
          backgroundColor: 'secondary.main',
        }}
        className="hero"
      >
        <img
          src="/hero-bg-md.jpg"
          srcSet="/hero-bg-sm.jpg 640w ,/hero-bg-md.jpg 1920w ,/hero-bg-lg.jpg 4000w"
          className="hero__thumb"
          alt=""
        />
        {/* grid col */}
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="h1"
            color="primary"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '2.5rem', sm: '3.5rem', md: '6rem' },
            }}
          >
            La Collection
          </Typography>
          <Typography
            color="primary"
            variant="subtitle1"
            sx={{ fontWeight: 'bold' }}
            component="p"
          >
            La Collection
          </Typography>
        </Container>

        {/* grid col */}
        <></>
      </Box>
    </>
  );
}
