import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { themeFactory, palette1 } from '../config/themes';

const theme = createTheme(themeFactory(palette1));
// import from config // add support white label in config
const MARKET = process.env.REACT_APP_MARKET;
console.log(MARKET);
// console.log(process.env);

export const UiThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    {/* <CssBaseline /> */}
  </ThemeProvider>
);
