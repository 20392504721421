export const palette1 = {
  type: 'light',
  primary: {
    main: '#000', //'#3e1d62', //#000 //'#52b753', //'#3a257e'//'#933434',
  },
  secondary: {
    main: '#fafafa',
  },
  black: {
    main: '#faf000', //'#db5656',
  },
  background: {
    default: '#fafafa',
    paper: '#fafafa',
  },
};
export const themeFactory = () => ({
  palette: { ...palette1 },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: palette1['black']['main'],
              color: palette1['background']['default'],
              boxShadow: 'none',
              border: `solid 1px ${palette1['black']['main']}`,
              '&:hover': {
                backgroundColor: 'primary.main',
                color: palette1['black']['main'],
                border: `solid 1px ${palette1['black']['main']}`,
                boxShadow: 'none',
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              backgroundColor: '#d150ff',
              color: palette1['background']['default'],
              border: `solid 1px ${palette1['black']['main']}`,
              boxShadow: 'none',
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              backgroundColor: 'transparent',
              color: palette1['black']['main'],
              border: `solid 1px ${palette1['black']['main']}`,
              paddingTop: '6px',
              paddingBottom: '6px',
              '&:hover': {
                backgroundColor: palette1['black']['main'],
                color: palette1['background']['default'],
                border: `solid 1px ${palette1['black']['main']}`,
                //
                // boxShadow: 'none',
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              backgroundColor: palette1['background']['default'],
              color: palette1['black']['main'],
              border: `solid 1px ${palette1['black']['main']}`,
              paddingTop: '6px',
              paddingBottom: '6px',
              '&:hover': {
                backgroundColor: palette1['black']['main'],
                color: palette1['background']['default'],
                // boxShadow: 'none',
              },
            }),
        }),
      },
    },
  },
});

/*


{
    components: {
      MuiButton: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                backgroundColor: '#000',
                color: '#fff',
                boxShadow: 'none',
                border: 'solid 1px #000',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: '#000',
                  border: 'solid 1px #000',
                  boxShadow: 'none',
                },
              }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'secondary' && {
                backgroundColor: '#d150ff',
                color: '#fff',
                border: 'solid 1px #000',
                boxShadow: 'none',
              }),
            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' && {
                backgroundColor: 'transparent',
                color: '#000',
                border: 'solid 1px #000',
                paddingTop: '6px',
                paddingBottom: '6px',
                '&:hover': {
                  backgroundColor: '#000',
                  color: '#fff',
                  border: 'solid 1px #000',
                  //
                  // boxShadow: 'none',
                },
              }),
            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'secondary' && {
                backgroundColor: '#fff',
                color: '#000',
                border: 'solid 1px #000',
                paddingTop: '6px',
                paddingBottom: '6px',
                '&:hover': {
                  backgroundColor: '#000',
                  color: '#fff',
                  // boxShadow: 'none',
                },
              }),
          }),
        },
      },
    },
    palette: {
      type: 'light',
      primary: {
        main: '#ede4e4',
      },
      secondary: {
        main: '#d150ff',
      },
      black: {
        main: '#000000',
      },
      background: {
        default: '#ffffff',
        paper: '#fafafa',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  }
**/
